import React, { useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import Select from "react-select";
import { ApiContext } from "../apiContext";

const mappingCode = (categoryArray) => {
  if (!categoryArray) {
    return { codes: [], names: [] };
  }

  const filteredOptions = categoryArray.filter(
    (option) => option.Status === true
  );
  const codes = filteredOptions.map((option) => option.Code);
  const names = filteredOptions.map((option) => option.Name);

  return { codes, names };
};

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
  interestedproduct,
  blogcategory,
  casestudies,
  videos,
  ebooks,
  solutions,
  products,
  emailcategory,
}) => {
  const mappingcategory = {
    postCategory: mappingCode(blogcategory).codes,
    caseStudiesCategory: mappingCode(casestudies).codes,
    videoscategory: mappingCode(videos).codes,
    ebookscategory: mappingCode(ebooks).codes,
    solutions: mappingCode(solutions).codes,
    interestedproduct: mappingCode(interestedproduct).codes,
    emailcategory: mappingCode(emailcategory).codes,
  };

  const mappingcategoryname = {
    postCategory: mappingCode(blogcategory).names,
    caseStudiesCategory: mappingCode(casestudies).names,
    videoscategory: mappingCode(videos).names,
    ebookscategory: mappingCode(ebooks).names,
    solutions: mappingCode(solutions).names,
    interestedproduct: mappingCode(interestedproduct).names,
    emailcategory: mappingCode(emailcategory).names,
  };

  const handleCheckboxChange = (event, name) => {
    const isChecked = event.target.checked;

    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        check: {
          ...prevModalData.check,
          [name]: isChecked,
        },
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        check: {
          ...prevAddCase.check,
          [name]: isChecked,
        },
        ...(isChecked
          ? {
              mappingcategory: {
                ...prevAddCase.mappingcategory,
                [name]: mappingcategory[name],
              },
            }
          : {}),
      }));
    }
  };

  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-12">
            <Label htmlFor="ip_type_name">Category</Label>
            <select
              id="interestedproduct"
              name="interestedproduct"
              className="select-list"
              onChange={(event) => handleInputChange(event, "ip_type_name")}
              value={getInputValue("ip_type_name")}
            >
              <option value="">-- Select Category --</option>
              {interestedproduct &&
                interestedproduct
                  .filter((option) => option.Status === true)
                  .map((option) => (
                    <option key={option.Code} value={option.Name}>
                      {option.Name}
                    </option>
                  ))}
            </select>
            <span className="non-valid">{formError.ip_type_name}</span>
          </div>
          <Label>Mapping Category Type</Label>
          <div className="category_list">
          {getInputValue("ip_type_name") &&
            [
              { key: "postCategory", label: "Blog" },
              { key: "caseStudiesCategory", label: "CaseStudies" },
              { key: "videoscategory", label: "Videos" },
              { key: "ebookscategory", label: "Ebooks" },
              { key: "emailcategory", label: "Emails" },
              { key: "solutions", label: "Solutions" },
              { key: "interestedproduct", label: "Products" },
            ].map((category) => (
              <div className="my-3 email" key={category.key}>
                <Label htmlFor="email-confi">{category.label}</Label>
                <Input
                  type="checkbox"
                  id="email-confi"
                  name="email-confi"
                  value="email-confi"
                  checked={
                    modalData?.check?.[category.key] ||
                    addCase?.check?.[category.key]
                  }
                  onChange={(event) =>
                    handleCheckboxChange(event, category.key)
                  }
                />
              </div>
            ))}
            </div>
          {getInputValue("ip_type_name") &&
            [
              {
                key: "postCategory",
                label: "Blog Category",
                name: "Blog",
              },
              {
                key: "caseStudiesCategory",
                label: "Casestudies Category",
                name: "Casestudies",
              },
              {
                key: "videoscategory",
                label: "Video Category",
                name: "Videos",
              },
              {
                key: "ebookscategory",
                label: "Ebooks Category",
                name: "Ebooks",
              },
              {
                key: "emailcategory",
                label: "Email Category",
                name: "Email",
              },
              {
                key: "solutions",
                label: "Solutions Category",
                name: "Solutions",
              },
              {
                key: "interestedproduct",
                label: "Products Category",
                name: "Products",
              },
            ]
              .filter(
                (category) =>
                  modalData?.check?.[category.key] ||
                  addCase?.check?.[category.key]
              )
              .map((category) => (
                <div className="email-show" key={category.key}>
                  <div className="my-3 col-md-12">
                    <Label htmlFor={category.key}>{category.label}</Label>
                    <Select
                      name={category.key}
                      placeholder="Select"
                      options={mappingcategoryname[category.key].map(
                        (value) => ({
                          value,
                          label: value,
                        })
                      )}
                      value={
                        modalData?.mappingcategoryname
                          ? (modalData.mappingcategoryname || []).find(
                              (item) => item.name === category.name
                            )?.data
                            ? modalData.mappingcategoryname
                                .find((item) => item.name === category.name)
                                .data.split(", ")
                                .map((value) => ({
                                  value,
                                  label: value,
                                }))
                            : "" // Set to empty string when there are no selected values
                          : addCase?.[category.key]?.mappingcategoryname.map(
                              (item) => ({
                                value: item.data,
                                label: item.data,
                              })
                            )
                      }
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(
                          (option) => option.label
                        );

                        if (!modalData) {
                          setCase((prevData) => ({
                            ...prevData,
                            mappingcategoryname: [
                              ...(prevData.mappingcategoryname || []).filter(
                                (item) => item.name !== category.name
                              ),
                              {
                                name: category.name,
                                data: selectedValues.join(", "),
                              },
                            ],
                          }));
                        } else {
                          const updatedMappingCategoryName = [
                            ...(modalData.mappingcategoryname || []).filter(
                              (item) => item.name !== category.name
                            ),
                            {
                              name: category.name,
                              data: selectedValues.join(", "),
                            },
                          ];

                          setModalData((prevData) => ({
                            ...prevData,
                            mappingcategoryname: updatedMappingCategoryName,
                          }));
                        }
                      }}
                      isMulti
                      isSearchable
                    />
                  </div>
                </div>
              ))}

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddForm;
