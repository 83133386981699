import React, { useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";

const ViewCase = ({ Modalclose, modalData }) => {
  const { data, error } = useContext(ApiContext);
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>CASE STUDIES DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Title</label>
                    <p>{modalData.title}</p>
                    <label className="heading">Company Name</label>
                    <p>{modalData.companyname}</p>
                    <label className="heading">URL</label>
                    <p>{modalData.url}</p>
                    <label className="heading">Alt Text</label>
                    <p>{modalData.alttext}</p>
                    <label className="heading">Category</label>
                    {modalData.category && (
                      <div className="category">
                        <span>
                          {
                            data.caseStudiesCategory.find(
                              (option) => option.Code === modalData.category
                            )?.Name
                          }
                        </span>
                      </div>
                    )}
                    <label className="heading">Use Case</label>
                    <p>{modalData.usecase}</p>
                    <label className="heading">Industry</label>
                    <p>{modalData.industry}</p>
                    <label className="heading">Region</label>
                    <p>{modalData.region}</p>
                    {modalData.shortdescription && (
                      <>
                        <label className="heading">Short Description</label>
                        <p>{modalData.shortdescription}</p>
                      </>
                    )}
                    {modalData.description && (
                      <>
                        <label className="heading">Description</label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: modalData.description || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.challenges && (
                      <>
                        <label className="heading">Challenges</label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.challenges || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.outcome && (
                      <>
                        <label className="heading">Outcome</label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.outcome || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.emailconfigurationflag && (
                      <div className="email-show">
                        <label className="heading">
                          Email send schedule in days
                        </label>
                        <p>{modalData.sendemaildays}</p>
                        <label className="heading">Email Template</label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.emailtemplate || "",
                          }}
                        />
                      </div>
                    )}
                    <label className="heading">Case Studies Image</label>
                    {modalData.thumbnail_image && (
                      <img
                        src={`${modalData.thumbnail_image}?${Date.now()}`}
                        alt="Thumbnail"
                      />
                    )}

                    <label className="heading">Case Studies Pdf</label>
                    {modalData.thumbnail_pdf && (
                      <a
                        href={modalData.thumbnail_pdf}
                        alt="Thumbnail"
                        download={modalData.thumbnail_pdf_name}
                      >
                        {modalData.thumbnail_pdf_name}
                      </a>
                    )}
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewCase;
