import { toast } from 'react-toastify';

const notify = {
    success: (message) => {
        toast.dismiss();
        toast.success(message, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            theme: "dark",
        });
    },
    error: (message) => {
        toast.dismiss();
        toast.error(message, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            theme: "dark",
        });
    },
    warning: () =>{
        toast.dismiss();
        toast.warning("fill the required fields", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            theme: "dark",
        });
    }
};

export default notify;
