import React, { useState, useContext, useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../../auth/AuthService";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import notify from "../../../helper/Notify";
import { useLoader } from "../../../helper/Loader";

export default function Service({
  closeModal,
  modalData,
  setModalData,
  State,
}) {
  const [formError, setFormError] = useState({});
  const { error, data, setData } = useContext(ApiContext);
  const {setIsLoader} = useLoader();
  const baseApi = window.API_CONFIG.BASE_URL;
  const apiBaseUrl = `${baseApi}${State}`;
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    Name: "",
    Code: generateUUID(),
  });
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };
  /* -----handleImage Upload function ----*/

  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["Name"];
    const validatefield = (field) => {
      if (!modalData && addCase[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
      if (modalData && modalData[field] === "") {
        errors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      }
    };
    fieldarr.forEach(validatefield);

    return errors;
  };

  const handleClear = () => {
    const clearfields = {
      Name: "",
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData(clearfields);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true);
    

    try {
      if (modalData) {
        // Updating an existing data entry
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const updatedCareerData = careerData.map((item) =>
            item.Code === modalData.Code ? { ...item, ...updatedData } : item
          );

          const duplicateItem = careerData.find(
            (item) =>
              item.Name === modalData.Name && item.Code !== modalData.Code
          );

          if (duplicateItem) {
            console.log(duplicateItem)
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("Data successfully updated:", updateResponse.data);
          setData((prevData) => ({
            ...prevData,
            [State]: updatedCareerData,
          }));

          closeModal();
          closeModal();
          notify.success(updateResponse.data.message);

          setCase({}); // Clear the addCase state for the next use
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const updatedData = [...oldData, updatedBlogData];
          const duplicateItem = oldData.find(
            (item) =>
              item.Name === addCase.Name
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          setData((prevData) => ({
            ...prevData,
            [State]: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);

          setCase({}); // Clear the addCase state for the next use
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false);
    }
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>{modalData ? "Edit" : "Add "}</h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                closeModal={closeModal}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
}
