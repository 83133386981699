import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

const ViewData = ({ Modalclose, modalData }) => {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Redirect Url Details</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Redirect From Url</label>
                    <a href={modalData.fromurl} target="_blank">
                      {modalData.fromurl}
                    </a>
                    <label className="heading">Redirect To Url</label>
                    <a href={modalData.tourl} target="_blank">
                      {modalData.tourl}
                    </a>
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewData;
