import React, { useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";

const ViewForm = ({ Modalclose, modalData }) => {
  const { data, error } = useContext(ApiContext);
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Email DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Name </label>
                    <p>{modalData.name}</p>
                    {modalData.description && (
                      <>
                        <label className="heading">Short Description</label>
                        <p>{modalData.description}</p>
                      </>
                    )}
                    {modalData.maileventtype && (
                      <>
                        <label className="heading">Mail Event Type</label>
                        <p>{modalData.maileventtype}</p>
                      </>
                    )}
                    {modalData.country && (
                      <>
                        <label className="heading">Country</label>
                       <p>{modalData.country}</p>
                      </>
                    )}
                    {modalData.body && (
                      <>
                        <label className="heading"> Description</label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: modalData.body || "",
                          }}
                        />
                      </>
                    )}

                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewForm;
