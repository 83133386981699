import React, {  createContext, useContext, useState } from 'react'

const LoaderContext = createContext() ;

export  const LoaderProvider = ({children}) =>{
    const [isLoader, setIsLoader] = useState(false)

    return (
      
        <LoaderContext.Provider value={{ isLoader, setIsLoader }}>
          {children}
          {isLoader && (
            
            <div id="loading-overlay" className="preloader-div-overlay ng-scope">
              <div className="preloader-div">
                <div className="preloader-wrapper big">
                  <div>
                    <img
                      src={require("../images/preloader.gif")}
                      style={{ width: "100px" }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoaderContext.Provider>
      );
}

export const useLoader = () =>{
    return useContext(LoaderContext)
}
