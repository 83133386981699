import React from "react";
import { FaRegWindowClose, FaTrashAlt } from "react-icons/fa";

const DeleteBox = ({ Modalclose, handleDelete }) => {
  return (
    <>
      <div className="modal-wrapper delete-modal" onClick={Modalclose}></div>
      <div className="modal-container delete-content">
        <div className="close-icon">
          <FaRegWindowClose onClick={Modalclose} />
        </div>
        <div className="modal-body">
          <section className="delete-blog">
            <div className="container">
              <div className="delete-box">
                <div className="del-icon">
                  <FaTrashAlt />
                </div>
                <h2>Are you sure?</h2>
                <p>
                  Do you really want to delete this records? This process cannot
                  be undone
                </p>
                <div className="delete-buttons">
                  <button
                    className="cancel-button btn btn-secondary"
                    onClick={Modalclose}
                  >
                    No
                  </button>
                  <button
                    className="confirm-button btn btn-danger"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default DeleteBox;
