import React, { useState, useEffect } from "react";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="footer">
      <p>Copyright © {currentYear} C-Zentrix. All rights reserved.</p>
    </div>
  );
}
