import React, { useState, useEffect } from "react";
import Service from "./Service";
import BlockAPi from "./BlockAPi";
import { Link, useLocation } from "react-router-dom";
import { Pagination } from "../../Pagination";
import { ViewModels } from "../../SidebarFormat";
import { FaPlusSquare, FaSearch } from "react-icons/fa";

export default function BlockeMaillist() {
  const pageSize = 6; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(1); // Total number of items
  const [showModal, setshowModal] = useState(false);
  const closeModal = () => setshowModal(false);
  const location = useLocation();
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const renderPageNumbers = () => {
    // Calculate the range of page numbers to display
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(Math.ceil(totalItems / pageSize), currentPage + 2);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }

    return pageNumbers;
  };

  const getmenu = () => {
    const matchingMenu = ViewModels.find((menu) =>
      menu.SubMenus.some((submenu) => location.pathname.includes(submenu.Href))
    );

    if (matchingMenu) {
      const matchingSubMenu = matchingMenu.SubMenus.find((submenu) =>
        location.pathname.includes(submenu.Href)
      );
      if (matchingSubMenu) {
        return matchingSubMenu; // Return the Title
      }
    }
    return null;
  };

  const getData = getmenu();
  const updateTotalItems = (length) => {
    setTotalItems(length);
  };
  return (
    <div className="row m-0 p-0">
      <section className="dash-section col-md-12  ">
        <div className="row p-0 m-0 right-content">
          <div className="head-name ">
            <h3>
              <Link to="/dashboard">DASHBOARD</Link>/ {getData.Title}
            </h3>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 right-content">
            <div className="data-content">
              <div className="top-button">
                <div className="searchBox blockSearch">
                  <input
                    className="searchText"
                    type="text"
                    placeholder="Type to Search..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="searchBtn">
                    <FaSearch />
                  </div>
                </div>
              </div>
              <table className="table table-striped table-hover blog-table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Edit</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <BlockAPi
                  page={currentPage}
                  pageSize={pageSize}
                  searchQuery={searchQuery}
                  totalLength={updateTotalItems}
                  State={getData.State}
                />
                <tbody>{/* Render your blog data here */}</tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={totalItems}
                pageSize={pageSize}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                renderPageNumbers={renderPageNumbers}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="top-button block-mail">
              <button
                type="button"
                className="new-blog"
                onClick={() => setshowModal(true)}
              >
                <FaPlusSquare />
              </button>
              {showModal && (
                <Service closeModal={closeModal} State={getData.State} />
              )}
            </div>
            <div className="notice-board data-content">
              <h3>Note</h3>
              <div className="board-desc">
                <img src={require("../../../images/icon-bulb-glow.png")} />
                <p>Add/Manage {getData.Title} from here.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
