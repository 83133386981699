import React, { useState, useEffect } from "react";
import { getToken, removeToken } from "../auth/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { useLoader } from "../helper/Loader";

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const{setIsLoader} = useLoader

  useEffect(() => {
    const tokenExists = !!getToken();
    setLoggedIn(tokenExists);

    if (location.pathname === "/") {
      setLoggedIn(false);
    } else if (tokenExists) {
      setLoggedIn(true);
    }
  }, [location]);

  const handleLogout = () => {
    
    removeToken(() => {
      setLoggedIn(false);
    });
    navigate("/");
  };
  const handleredirect = () => {
    navigate("/dashboard");
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="head-block">
          <div className="logo-image">
            <img
              src={require("../images/logo-new.png")}
              onClick={handleredirect}
              alt=""
            />
          </div>

          {loggedIn && (
            <div className="login-button">
              <FaSignOutAlt onClick={handleLogout} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
