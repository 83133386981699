import React, { useState, useEffect, useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import { ApiContext } from "../apiContext";
import AddForm from "./AddForm";
import { getToken } from "../../auth/AuthService";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useLoader } from "../../helper/Loader";
import notify from "../../helper/Notify";

const Service = ({ closeModal, modalData, setModalData, updateTableData }) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const { data, error, setData } = useContext(ApiContext);
  const [formError, setFormError] = useState({});
  const apiBaseUrl = window.API_CONFIG.All_Api.ipmapping;
  const{setIsLoader} = useLoader();
  const token = getToken();
  const generateUUID = () => {
    const uuid = uuidv4();
    const fiveDigitUUID = uuid.replace(/-/g, "").substr(0, 5);
    return fiveDigitUUID;
  };
  const [addCase, setCase] = useState({
    code: generateUUID(),
  });

  /*---- get all the input field ----*/
  const getInputValue = (field) => {
    return modalData ? modalData[field] : addCase[field];
  };
  /*---- change input field function ----*/
  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (modalData) {
      setModalData((prevModalData) => ({
        ...prevModalData,
        [field]: value,
      }));
    } else {
      setCase((prevAddCase) => ({
        ...prevAddCase,
        [field]: value,
      }));
    }
  };

  /*---- handle clear function----*/
  const handleClear = () => {
    const clearfields = {
      ip_type_name: "",
      mappingcategoryname: "",
      status: false,
    };
    if (!modalData) {
      setCase(clearfields);
    } else {
      setModalData((prevModalData) => ({
        ...prevModalData,
        ...clearfields,
      }));
    }
  };

  /*---- validation on form fields----*/
  const validateFormFields = () => {
    const errors = {};
    const fieldarr = ["ip_type_name"];

    const validatefield = (field) => {
      if (field === "ip_type_name") {
        if (!modalData) {
          if (!addCase || !addCase[field]) {
            errors[field] = `Please select a category`;
          }
        } else {
          if (!modalData[field]) {
            errors[field] = `Please select a category`;
          }
        }
      }
    };

    fieldarr.forEach(validatefield);

    return errors;
  };

  /* form submit function*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateFormFields();

    if (Object.keys(formErrors).length > 0) {
      setFormError(formErrors);
      notify.warning();
      return; 
    }

    setFormError(""); 
    setIsLoader(true)

    try {
      if (modalData) {
        // Updating an existing data entry
        const updatedData = {
          ...modalData,
          updatedDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const careerData = response.data.data?.ViewModels || [];
          const duplicateItem = careerData.find(
            (item) =>
              item.ip_type_name === modalData.ip_type_name &&
              item.code !== modalData.code
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be updated.");
            return;
          }
          const updatedCareerData = careerData.map((item) =>
            item.code === modalData.code ? { ...item, ...updatedData } : item
          );

          const updateResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedCareerData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("Data successfully updated:", updateResponse.data);
          if(updateResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            ipmapping: updatedCareerData,
          }));

          closeModal();
          notify.success(updateResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(updateResponse.data.message);
        }
        } catch (error) {
          console.error("Error updating data:", error);
          notify.error(error.message);
        }
      } else {
        // Adding a new data entry
        const updatedBlogData = {
          ...addCase,
          createdDate: new Date().toISOString().slice(0, 10),
        };

        try {
          const response = await axios.get(apiBaseUrl, {
            headers: {
              "x-access-token": token,
            },
          });

          const oldData = response.data.data?.ViewModels || [];
          const duplicateItem = oldData.find(
            (item) => item.ip_type_name === addCase.ip_type_name 
          );

          if (duplicateItem) {
            notify.error("Data is a duplicate and cannot be added.");
            return;
          }
          const updatedData = [...oldData, updatedBlogData];

          const addResponse = await axios.post(
            apiBaseUrl,
            { ViewModels: updatedData },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-access-token": token,
              },
            }
          );

          console.log("New data added successfully:", addResponse.data);
          if(addResponse.data.success === true){
          setData((prevData) => ({
            ...prevData,
            ipmapping: updatedData,
          }));

          closeModal();
          notify.success(addResponse.data.message);

          setCase({}); 
        }
        else{
          notify.error(addResponse.data.message);
        }
        } catch (error) {
          console.error("Error adding new data:", error);
          notify.error(error.message);
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      notify.error(error.message);
    }
    finally{
      setIsLoader(false)
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>
            {modalData
              ? "Edit  Interested Product Mapping "
              : "Add  Interested Product Mapping"}
          </h1>
          <div className="close-icon">
            <FaWindowClose onClick={closeModal} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <AddForm
                handleSubmit={handleSubmit}
                formError={formError}
                getInputValue={getInputValue}
                handleInputChange={handleInputChange}
                handleClear={handleClear}
                modalData={modalData}
                addCase={addCase}
                setModalData={setModalData}
                setCase={setCase}
                interestedproduct={data.interestedproduct}
                blogcategory={data.postCategory}
                casestudies={data.caseStudiesCategory}
                videos={data.videoscategory}
                ebooks={data.ebookscategory}
                solutions={data.solutions}
                products={data.interestedproduct}
                emailcategory={data.maileventtype}
                closeModal={closeModal}
              />
            </CardBody>
            <div className="avc"></div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Service;
