import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }
  const questions = Object.values(modalData.question);
  const answers = Object.values(modalData.answers);
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>FAQS DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Page</label>
                    <p>{modalData.page}</p>
                    {questions.map((question, index) => (
                      <div key={index}>
                        <label className="heading">Question {index + 1}</label>
                        <p>{question}</p>
                        <label className="heading">Answer {index + 1}</label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: answers[index] || "",
                          }}
                        />
                      </div>
                    ))}
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
