import React, { useContext } from "react";
import { ApiContext } from "../apiContext";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

const ViewEbook = ({ Modalclose, modalData }) => {
  const { data, error } = useContext(ApiContext);
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>EBOOKS DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Title</label>
                    <p>{modalData.title}</p>
                    <label className="heading">Author</label>
                    <p>{modalData.author}</p>
                    <label className="heading">Category</label>
                    {modalData.category && (
                      <div className="category">
                        <span>
                          {modalData.category
                            .map((category) => {
                              const foundOption = data.ebookscategory.find(
                                (option) => option.Code === category
                              );
                              const label = foundOption ? foundOption.Name : "";
                              return label;
                            })
                            .join(", ")}
                        </span>
                      </div>
                    )}
                    {modalData.description && (
                      <>
                        <label className="heading">Description</label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: modalData.description || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.emailconfigurationflag && (
                      <div className="email-show">
                        <label className="heading">
                          Email send schedule in days
                        </label>
                        <p>{modalData.sendemaildays}</p>
                        <label className="heading">Email Template</label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.emailtemplate || "",
                          }}
                        />
                      </div>
                    )}
                    <label className="heading">Ebooks Image</label>
                    {modalData.thumbnail_image && (
                      <img
                        src={`${modalData.thumbnail_image}?${Date.now()}`}
                        alt="Thumbnail"
                      />
                    )}

                    <label className="heading">Ebooks Pdf</label>
                    {modalData.thumbnail_pdf && (
                      <a
                        href={modalData.thumbnail_pdf}
                        alt="Thumbnail"
                        download={modalData.thumbnail_pdf_name}
                      >
                        {modalData.thumbnail_pdf_name}
                      </a>
                    )}
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewEbook;
