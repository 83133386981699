import axios from "axios";

const apiBaseUrl = window.API_CONFIG.imageupload;

export const imageUpload = async (title, base64, imgDir, token) => {
 
  if (typeof base64 !== 'string' || !base64) {
    return base64;
  }

  if (!base64.startsWith("data:image/")) {
    return base64; 
  }

  try {
    const response = await axios.post(
      apiBaseUrl,
      {
        dir_name: imgDir,
        thumbnail_image: base64,
        title: title,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    const abc = response.data.data;
    return abc;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};
