import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setToken } from "../auth/AuthService";
import { FaUser, FaLock } from "react-icons/fa";
import notify from "../helper/Notify";
import { useLoader } from "../helper/Loader";

export default function LoginForm() {
  const navigate = useNavigate();
  const [emailId, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const{setIsLoader} = useLoader();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the login credentials to the authentication API
      const response = await fetch(`${window.API_CONFIG.BASE_URL}login`, {
        method: "POST",
        body: JSON.stringify({ emailId, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.token) {
        setToken(data.token);
        console.log(data.message);
        setIsLoader(true)
        navigate("/dashboard");
      } else {
        notify.error(data.message);
      }
    } catch (error) {
      notify.error(error.message);
      console.error(error);
    }
    finally{
      setIsLoader(false);
    }
  };

  // /* End Auth handle */
  return (
    <>
      <div className="bg-image">
        <div className="loginbox">
          <div className="avtar-image">
            <img
              src={require("../images/avatar-image.png")}
              alt=""
              className="login-image"
            />
          </div>
          <h1>Sign in</h1>
          <form onSubmit={handleSubmit}>
            {/* <p>{message}</p> */}
            <p>Email:</p>
            <div className="input-field">
              <FaUser className="input-icon" />
              <input
                type="email"
                name="email"
                value={emailId}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                required
              />
            </div>

            <p>Password:</p>
            <div className="input-field">
              <FaLock className="input-icon" />
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                required
              />
            </div>
            <button
              className="log-in"
              // disabled={!emailId || !password}
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
