import React, { useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import { ApiContext } from "../apiContext";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
  generateCustomURL,
}) => {
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-12">
            <Label for="fromurl">Redirect From Url</Label>
            <Input
              type="text"
              id="fromurl"
              name="fromurl"
              placeholder="Redirect From Url"
              required
              value={getInputValue("fromurl")}
              onChange={(event) => handleInputChange(event, "fromurl")}
            />
            <span className="non-valid">{formError.fromurl}</span>
            <button
              type="button"
              onClick={() => {
                generateCustomURL(); // Call the generateCustomURL function when the button is clicked
              }}
              className="generate-url"
            >
              Generate Short-URL
            </button>
          </div>

          <div className="my-3 col-md-12">
            <Label for="tourl">Redirect To Url</Label>
            <Input
              type="text"
              id="tourl"
              placeholder="Redirect To Url"
              value={getInputValue("tourl")}
              onChange={(event) => handleInputChange(event, "tourl")}
            />
            <span className="non-valid">{formError.tourl}</span>
          </div>
          <div className="my-3 col-md-12">
            <Label for="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
