import React, { useContext, useState, useEffect } from "react";

import {FaEdit } from "react-icons/fa";
import Service from "./Service";
import { ApiContext } from "../../apiContext";

function BlockAPi({ page = 1, pageSize, searchQuery, totalLength, State }) {
  const { data, error } = useContext(ApiContext);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const modalClose = () => setEditModal(false);
  useEffect(() => {
    try {
      if (!data || !data[State] || data[State].length === 0) {
        throw new Error("No data available");
      }
  
      const filteredData = data[State].filter((item) => {
        const name = item?.Name || "";
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      });
  
      const sortedData = filteredData.sort(
        (a, b) =>
          a.Name.localeCompare(b.Name) ||
          new Date(a.updatedDate) - new Date(b.updatedDate)
      );
  
      setFilteredBlog(sortedData);
      totalLength(filteredData.length);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }, [data, State, searchQuery, totalLength]); // Assuming 'state' is the correct variable name, adjust if needed
  

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const items = filteredBlog
    .slice(startIndex, endIndex)
    .map((viewModel, index) => {
      const id = `item-${index}`;
      const fetchData = async () => {
        try {
          setModalData(viewModel);
        } catch (error) {
          console.error(error);
        }
      };
      return (
        <tr key={id}>
          <td
            title="Edit"
            onClick={() => {
              setEditModal(true);
              fetchData();
            }}
          >
            <FaEdit />
          </td>
          <td width="55%">
            {viewModel && viewModel.Name
              ? viewModel.Name.substring(0, 20)
              : "Title not available"}
          </td>
          <td width="30%">
            <span
              className={`status ${viewModel.Status ? "active" : "inactive"}`}
            >
              {viewModel.Status ? "active" : "inactive"}
            </span>
          </td>

          {editModal && (
            <Service
              closeModal={modalClose}
              modalData={modalData}
              setModalData={setModalData}
              setFilteredBlog={setFilteredBlog}
              State={State}
            />
          )}
        </tr>
      );
    });

  return <tbody> {filteredBlog.length === 0 ? (
    <tr>
    <td classname ="noFound" colSpan="12">No data found</td>
    </tr>
  ) : (
    items
  )}</tbody>;
}
export default BlockAPi;
