import React, { useState } from "react";
import CareerApi from "./CareerApi";
import { Pagination } from "../Pagination";
import Service from "./Service";
import { FaPlusSquare, FaSearch } from "react-icons/fa";

export default function Career() {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1); 
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(1);
  const [showModal, setshowModal] = useState(false);
  const closeModal = () => setshowModal(false);
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const renderPageNumbers = () => {
    // Calculate the range of page numbers to display
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(Math.ceil(totalItems / pageSize), currentPage + 2);

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }

    return pageNumbers;
  };
  const updateTotalItems = (length) => {
    setTotalItems(length);
  };
  return (
    <div>
      <section className="dash-section">
        <div className="container-fluid p-0">
          <div className="row p-0 m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 right-content">
              <div className="head-name">
                <h3>Career</h3>
              </div>

              <div className="data-content">
                <div className="top-button">
                  <button
                    type="button"
                    className="new-blog"
                    onClick={() => setshowModal(true)}
                  >
                    <FaPlusSquare />
                  </button>
                  {showModal && <Service closeModal={closeModal} />}
                  <div className="searchBox">
                    <input
                      className="searchText"
                      type="text"
                      placeholder="Type to Search..."
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className="searchBtn">
                      <FaSearch />
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-hover blog-table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">View</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Delete</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date Modified</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <CareerApi
                    page={currentPage}
                    pageSize={pageSize}
                    searchQuery={searchQuery}
                    totalLength={updateTotalItems}
                  />
                  <tbody>{/* Render your blog data here */}</tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalItems={totalItems}
                  pageSize={pageSize}
                  onPrevPage={handlePrevPage}
                  onNextPage={handleNextPage}
                  renderPageNumbers={renderPageNumbers}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
