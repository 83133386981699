import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./AuthService";

export const PrivateRoute = () => {
  const [auth, setAuth] = useState(isAuthenticated());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAuth(isAuthenticated());
    }, 3000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return auth ? <Outlet /> : <Navigate to="/" />;
};
