import React, { useState, useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import { ApiContext } from "../apiContext";
import Select from "react-select";

const AddForm = ({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
}) => {
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="title">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="url">Author</Label>
            <Input
              type="text"
              id="author"
              name="author"
              placeholder="Author"
              value={getInputValue("author")}
              onChange={(event) => handleInputChange(event, "author")}
            />
            <span className="non-valid">{formError.author}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label className="heading">Time Duration</Label>
            <div className="row">
              <div className="col-md-6">
                <Label htmlFor="durationminute">Minute</Label>
                <Input
                  type="number"
                  id="durationminute"
                  name="durationminute"
                  value={getInputValue("durationminute")}
                  onChange={(event) =>
                    handleInputChange(event, "durationminute")
                  }
                />
                <span className="non-valid">{formError.durationminute}</span>
              </div>
              <div className="col-md-6">
                <Label htmlFor="url">Second</Label>
                <Input
                  type="number"
                  id="durationsecond"
                  name="durationsecond"
                  value={getInputValue("durationsecond")}
                  onChange={(event) =>
                    handleInputChange(event, "durationsecond")
                  }
                />
                <span className="non-valid">{formError.durationsecond}</span>
              </div>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="url">ThumbnailUrl</Label>
            <Input
              type="text"
              id="thumbnailurl"
              name="thumbnailurl"
              placeholder="ThumbnailUrl"
              value={getInputValue("thumbnailurl")}
              onChange={(event) => handleInputChange(event, "thumbnailurl")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="url">ContentUrl</Label>
            <Input
              type="text"
              id="contentUrl"
              name="contentUrl"
              placeholder="https://www.example.com/video/123/file.mp4"
              value={getInputValue("contentUrl")}
              onChange={(event) => handleInputChange(event, "contentUrl")}
            />
            <span className="non-valid">{formError.contentUrl}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="url">EmbedUrl</Label>
            <Input
              type="text"
              id="embedUrl"
              name="embedUrl"
              placeholder="https://www.example.com/embed/123"
              value={getInputValue("embedUrl")}
              onChange={(event) => handleInputChange(event, "embedUrl")}
            />
            <span className="non-valid">{formError.embedUrl}</span>
          </div>

          <div className="my-3 col-md-12">
            <Label for="author-bio">Description</Label>
            <textarea
              type="text"
              id="description"
              name="description"
              value={getInputValue("description")}
              onChange={(event) => handleInputChange(event, "description")}
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};
export default AddForm;
