import React from "react";
import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Interested Product Mapping DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Interested Product</label>
                    <p>{modalData.ip_type_name}</p>
                    <div className="category-name" width="33%">
                      {modalData.mappingcategoryname.map((item, index) => {
                        // Check if the item has data
                        if (item.data) {
                          return (
                            <tr key={index}>
                              <td>
                                <strong>{item.name + ":"}</strong>
                              </td>
                              <td className="category-data">{item.data}</td>
                            </tr>
                          );
                        } else {
                          // Return null if there is no data, effectively skipping this item
                          return null;
                        }
                      })}
                    </div>
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
