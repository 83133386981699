import React, { useContext } from "react";
import { Form, Label, Input } from "reactstrap";
import { ApiContext } from "../apiContext";
import { Textarea } from "@mobiscroll/react-lite";

export default function AddForm({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleImageUpload,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
  closeModal,
  thumbnail_image_name,
  type,
}) {
  const { error } = useContext(ApiContext);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label for="title">Title</Label>
            <Input
              type="text"
              id="title"
              name="title"
              placeholder="Title"
              value={getInputValue("title")}
              onChange={(event) => handleInputChange(event, "title")}
            />
            <span className="non-valid">{formError.title}</span>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={getInputValue("name")}
              onChange={(event) => handleInputChange(event, "name")}
            />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.status) || addCase.status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        status: !prevModalData.status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        status: !prevAddCase.status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="designation">Designation</Label>
            <Input
              type="text"
              id="designation"
              name="designation"
              placeholder="Designation"
              value={getInputValue("designation")}
              onChange={(event) => handleInputChange(event, "designation")}
            />
          </div>

          <div className="my-3 col-md-6">
            <Label htmlFor="video_url">Video URL</Label>
            <Input
              type="text"
              id="video_url"
              name="video_url"
              placeholder="Enter Video URL"
              value={getInputValue("video_url")}
              onChange={(event) => handleInputChange(event, "video_url")}
            />
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="video_type_name">Video Type</Label>
            <select
              id="video_type_name"
              name="video_type_name"
              className="select-list"
              onChange={(event) => handleInputChange(event, "video_type_name")}
              value={getInputValue("video_type_name")}
            >
              <option value="">-- Select Video Type --</option>
              {type &&
                type
                  .filter((option) => option.Status === true)
                  .map((option) => (
                    <option
                      key={option.video_type_code}
                      value={option.video_type_name}
                    >
                      {option.Name}
                    </option>
                  ))}
            </select>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="imagefile">
              Select Image for Video (Upload only images up to 100kb)
            </Label>
            <div className="file-field">
              <div className="btn helensys-theme-btn btn-sm ml-0">
                <span>Choose File</span>
                <Input
                  id="documentuplad"
                  name="imagefile"
                  type="file"
                  onChange={(event) =>
                    handleImageUpload(event, "thumbnail_image")
                  }
                  accept="image/gif, image/jpeg, image/png, image/webp*"
                  encType="multipart/form-data"
                />
              </div>
              <div className="file-path-wrapper">
                <Input
                  className="file-path validate"
                  value={
                    modalData
                      ? modalData.thumbnail_image_name
                      : addCase.thumbnail_image_name
                  }
                  type="text"
                  placeholder=""
                  onChange={(event) =>
                    handleInputChange(event, "thumbnail_image_name")
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="subtitle">Short Description</Label>
            <Textarea
              type="text"
              id="shortdescription"
              name="shortdescription"
              placeholder="Short Description"
              value={getInputValue("shortdescription")}
              onChange={(event) => handleInputChange(event, "shortdescription")}
            />
          </div>

          <div className="my-3 col-md-12">
            <Label htmlFor="author-bio">Description</Label>
            <textarea
              type="text"
              id="description"
              name="description"
              value={getInputValue("description")}
              onChange={(event) => handleInputChange(event, "description")}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
