import React, { useState, useRef, useEffect, useContext } from "react";
import { Card, CardBody, Form, Label, Input } from "reactstrap";
import { ApiContext } from "../../apiContext";

export default function AddForm({
  handleSubmit,
  formError,
  getInputValue,
  handleInputChange,
  handleClear,
  modalData,
  addCase,
  setModalData,
  setCase,
}) {
  const { error } = useContext(ApiContext);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="row edit-form">
          <div className="my-3 col-md-6">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={getInputValue("Name")}
              onChange={(event) => handleInputChange(event, "Name")}
            />
            <span className="non-valid">{formError.Name}</span>
          </div>
          <div className="my-3 col-md-6">
            <Label htmlFor="Status">Status</Label>
            <div className="toggle">
              <span>Inactive</span>
              <label
                className={`switch ${
                  (modalData && modalData.Status) || addCase.Status
                    ? "true"
                    : ""
                }`}
              >
                <Input
                  type="checkbox"
                  checked={modalData && modalData.Status}
                  onChange={() => {
                    if (modalData) {
                      setModalData((prevModalData) => ({
                        ...prevModalData,
                        Status: !prevModalData.Status,
                      }));
                    } else {
                      setCase((prevAddCase) => ({
                        ...prevAddCase,
                        Status: !prevAddCase.Status,
                      }));
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <span>Active</span>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning ms-2"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary ms-2"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
