import React, { useEffect, useState } from "react";

export function Pagination({
  currentPage,
  totalItems,
  pageSize,
  onPrevPage,
  onNextPage,
  renderPageNumbers,
  setCurrentPage
}) {
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    const calculatedTotalPages = Math.ceil(totalItems / pageSize);
    setTotalPages(calculatedTotalPages);
    if (totalItems <= pageSize){
      setCurrentPage(1)
    }
  }, [totalItems, pageSize]);

  if (totalItems === 0 || totalPages === 1) {
    return null; // Return null if there are no items to display or only one page
  }

  return (
    <div className="pagination">
      {currentPage !== 1 && <button onClick={onPrevPage}>Previous</button>}
      <ul className="pagi-number">{renderPageNumbers()}</ul>
      {currentPage !== totalPages && <button onClick={onNextPage}>Next</button>}
    </div>
  );
}
