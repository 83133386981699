import { jwtDecode } from "jwt-decode";


const TOKEN_NAME = "data";
const INACTIVITY_TIMEOUT = 30 * 60 * 1000; 

let inactivityTimer;

const validateToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; 
    return decoded.exp && decoded.exp > currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

export const setToken = (token) => {
  if (validateToken(token)) {
    const tokenData = JSON.stringify(token);
    localStorage.setItem(TOKEN_NAME, tokenData);
    resetInactivityTimer();
  } else {
    console.error('Invalid or expired token');
  }
};

export const getToken = () => {
  const tokenData = localStorage.getItem(TOKEN_NAME);
  if (tokenData) {
    const token = JSON.parse(tokenData);
    if (validateToken(token)) {
      resetInactivityTimer();
      return token;
    } else {
      removeToken();
      return null;
    }
  }
  return null;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_NAME);
  resetInactivityTimer();
};

export const isAuthenticated = () => {
  const token = getToken();
  return token !== null && token !== undefined;
};

export const logout = () => {
  removeToken();
};

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    removeToken();
  }, INACTIVITY_TIMEOUT);
};
