import React, { useContext } from "react";
import { FaWindowClose } from "react-icons/fa";
import { ApiContext } from "../apiContext";
import { Card, CardBody } from "reactstrap";

const ViewCareer = ({ Modalclose, modalData }) => {
  const { data } = useContext(ApiContext);
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }

  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>CAREER DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Title / Role</label>
                    <p>{modalData.title}</p>
                    <label className="heading">Location</label>
                    <p>{modalData.location}</p>
                    <label className="heading">Category</label>
                    <p>
                      {modalData.category &&
                        data.careerCategory.find(
                          (option) => option.Code === modalData.category
                        )?.Name}{" "}
                    </p>
                    {modalData.responsibilities && (
                      <>
                        <label className="heading">
                          Responsibilities Description
                        </label>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: modalData.responsibilities || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.knowledgeandskills && (
                      <>
                        <label className="heading">
                          Knowledgeand Skills Description
                        </label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.knowledgeandskills || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.educationandexperience && (
                      <>
                        <label className="heading">
                          Education And Experience Description
                        </label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.educationandexperience || "",
                          }}
                        />
                      </>
                    )}
                    {modalData.description && (
                      <>
                        <label className="heading">Other Description</label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.description || "",
                          }}
                        />
                      </>
                    )}
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewCareer;
