import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <div className="error-page">
      <img
        className="error-image"
        src={require("../images/404.png")}
        alt="404 Error"
      />
      <h1 className="error-heading">404</h1>
      <p className="error-text">
        Oops! The page you're looking for doesn't exist.
      </p>
      <p className="error-text">Let's get you back on track.</p>
      <Link to="/" className="go-home-link">
        Go to Homepage
      </Link>
    </div>
  );
}
