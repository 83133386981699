import React from "react";
import Sidebar from "./Sidebar";

export default function Dashboard() {
  return (
    <>
      <section className="dash-section">
        <div className="container-fluid">
          <div className="row p-0 m-0 user-dashboard">
            {/* <div className="col-lg-3 col-md-5 col-sm-12 p-0">
              <SidebarFormat />
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 right-content">
              <div className="head-name">
                <h3>Dashboard</h3>
                <div className="das-menu">
                  <Sidebar></Sidebar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
