import { FaWindowClose } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";

export default function ViewForm({ Modalclose, modalData }) {
  if (!modalData) {
    return null; // or return some default content if modalData is null or undefined
  }
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="modal-container">
        <div className="modal-head">
          <h1>Webinar DETAILS</h1>
          <div className="close-icon">
            <FaWindowClose onClick={Modalclose} />
          </div>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <section className="view-blog view-sec">
                <div className="container">
                  <div className="mt-3 view-text">
                    <label className="heading">Title</label>
                    <p>{modalData.title}</p>
                    <label className="heading">Video Url</label>
                    <p>{modalData.thumbnailurl}</p>
                    <label className="heading">Time Duration</label>
                    <p>{`${modalData.durationminute} Minute, ${modalData.durationsecond} Second`}</p>
                    <label className="heading">ContentUrl</label>
                    <a href={modalData.contentUrl} target="_blank">
                      {modalData.contentUrl}
                    </a>
                    <label className="heading">EmbedUrl</label>
                    <a href={modalData.embedUrl} target="_blank">
                      {modalData.embedUrl}
                    </a>
                    <label className="heading">Author</label>
                    <p>{modalData.author}</p>

                    <label className="heading">Description</label>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: modalData.description || "",
                      }}
                    />
                    <label className="heading">Status</label>
                    <div className="status-details">
                      <span
                        className={`status ${
                          modalData.status ? "active" : "inactive"
                        }`}
                      >
                        {modalData.status ? "active" : "inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
